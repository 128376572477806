export default [
    {
        path: "/",
        redirect: "/dashboard",
    },
    // {
    //     path: "/:pathMatch(.*)*",
    //     redirect: "/404-not-found",
    // },
];
