import { createMongoAbility } from "@casl/ability";

import Api from "@/axios";

const ability = createMongoAbility([{ action: "read", subject: "guest" }]);

function updateAbility(value) {
    var rules = [
        { action: "read", subject: "guest" },
        { action: ["read", "post"], subject: "auth" },
    ];
    if (value.status == "pending") {
        rules.push({ action: "read", subject: "pending" });
    } else {
        value.type.access.forEach((e) => {
            rules.push(e);
        });
    }
    ability.update(rules);
}

let authData = null;

async function getAuthData() {
    try {
        const res = await Api.get("/auth");
        authData = res;
    } catch (error) {
        console.log(error);
    }
}

await getAuthData();

export const userLogin = authData;
export const success = authData ? true : false;

if (userLogin.data) {
    updateAbility(userLogin.data);
}

export default ability;
