const menu = [
    {
        name: "dashboard",
        icon: "icon-[ic--outline-dashboard]",
        title: "Dashboard",
        access: "auth",
        type: "btn",
    },
    {
        name: "perawatanPasien",
        icon: "icon-[tabler--bed-filled]",
        title: "Perawatan Pasien",
        access: "admin",
        type: "btn",
    },
    {
        name: "kunjunganPasien",
        icon: "icon-[ic--round-accessible-forward]",
        title: "Kunjungan Pasien",
        access: "admin",
        type: "btn",
    },
    {
        name: "kunjunganRth",
        icon: "icon-[tabler--home-plus]",
        title: "Kunjungan RTH",
        access: "admin",
        type: "btn",
    },
    {
        icon: "icon-[tabler--database]",
        title: "Master Data",
        access: "admin",
        type: "dropdown",
        list: [
            {
                name: "skBed",
                icon: "icon-[tabler--bed]",
                title: "SK Tempat Tidur",
                access: "admin",
            },
            {
                name: "ruangan",
                icon: "icon-[tabler--building]",
                title: "Ruangan",
                access: "admin",
            },
        ],
    },
];

export default menu;
