import { createWebHistory, createRouter } from "vue-router";
import redirect from "./redirect";
import master from "./master";
import account from "./account";
import guest from "./guest";
import ability from "@/plugins/casl/ability";
import { nextTick } from "vue";

const routes = [
    ...redirect,
    ...master,
    ...account,
    ...guest,
    {
        path: "/dashboard-fullscreen",
        name: "dashboardFull",
        component: () => import("@/pages/home/Dashboard.vue"),
        meta: {
            pageTitle: "Dashboard",
            icon: "icon-[ic--outline-dashboard]",
            layout: "LayoutBlank",
            resource: "auth",
            action: "read",
        },
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/pages/home/Dashboard.vue"),
        meta: {
            pageTitle: "Dashboard",
            icon: "icon-[ic--outline-dashboard]",
            layout: "LayoutAuth",
            resource: "auth",
            action: "read",
        },
    },
    {
        path: "/perawatan-pasien",
        name: "perawatanPasien",
        component: () => import("@/pages/perawatan/Perawatan.vue"),
        meta: {
            pageTitle: "Perawatan Pasien",
            icon: "icon-[tabler--bed-filled]",
            layout: "LayoutAuth",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/kunjungan-pasien",
        name: "kunjunganPasien",
        component: () => import("@/pages/kunjungan-pasien/KunjunganPasien.vue"),
        meta: {
            pageTitle: "Kunjungan PASIEN",
            icon: "icon-[ic--round-accessible-forward]",
            layout: "LayoutAuth",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/kunjungan-rth",
        name: "kunjunganRth",
        component: () => import("@/pages/kunjungan-rth/KunjunganRth.vue"),
        meta: {
            pageTitle: "Kunjungan RTH",
            icon: "icon-[tabler--home-plus]",
            layout: "LayoutAuth",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/daftar-kasus",
        name: "daftarKasus",
        component: () => import("@/pages/menu/daftar-kasus/DaftarKasus.vue"),
        meta: {
            pageTitle: "Daftar Kasus",
            icon: "icon-[ic--outline-dashboard]",
            layout: "LayoutAuth",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/all-dashboard",
        name: "allDashboard",
        component: () => import("@/pages/all-dashboard/Dashboard.vue"),
        meta: {
            pageTitle: "Dashboard",
            icon: "icon-[tabler--layout-dashboard]",
            layout: "LayoutAuth",
            resource: "admin",
            action: "read",
        },
    },
];

const router = createRouter({
    // history: createWebHistory(import.meta.env.BASE_URL),
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (from.meta.savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const hasLogin = ability.can("read", "auth");
    const canNavigate = to.matched.some((route) => {
        if (Array.isArray(route.meta.resource)) {
            let resource = route.meta.resource;
            const result = resource.some((r) => {
                return ability.can(route.meta.action || "read", r);
            });
            return result;
        } else {
            return ability.can(
                route.meta.action || "read",
                route.meta.resource
            );
        }
    });
    if (!canNavigate) {
        return next("/not-authorized");
    }
    if (hasLogin && to.name === "login") {
        next({ name: "dashboard", replace: true });
        return;
    }
    next();
});

router.afterEach((to, from) => {
    nextTick().then(() => {
        // window.scrollTo(0, 0);
        document.title = to.meta.pageTitle + " - Silaris" || "Silaris";
    });
});

export default router;
