export default [
    {
        path: "/master/user",
        name: "user",
        component: () => import("@/pages/master/users/User.vue"),
        meta: {
            pageTitle: "User",
            icon: "icon-[tabler--user-up]",
            layout: "LayoutAuth",
            resource: "all",
            action: "read",
        },
    },
    // {
    //     path: "/master/jenis-perawatan",
    //     name: "jenisPerawatan",
    //     component: () =>
    //         import("@/pages/master/jenis-perawatan/JenisPerawatan.vue"),
    //     meta: {
    //         pageTitle: "Jenis Perawatan",
    //         icon: "icon-[tabler--user-up]",
    //         layout: "LayoutAuth",
    //         resource: "admin",
    //         action: "read",
    //     },
    // },
    {
        path: "/master/ruangan",
        name: "ruangan",
        component: () => import("@/pages/master/ruangan/Ruangan.vue"),
        meta: {
            pageTitle: "Ruangan",
            icon: "icon-[tabler--building]",
            layout: "LayoutAuth",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/master/sk-tempat-tidur",
        name: "skBed",
        component: () => import("@/pages/master/sk-bed/SkBed.vue"),
        meta: {
            pageTitle: "SK Tempat Tidur",
            icon: "icon-[tabler--bed]",
            layout: "LayoutAuth",
            resource: "admin",
            action: "read",
        },
    },
];
